@font-face {
  font-family: "Branding Bold";
  src: url(./fonts/Branding-Bold.woff) format("woff");
}
@font-face {
  font-family: "Branding Medium";
  src: url(./fonts/Branding-Medium.woff) format("woff");
}
@font-face {
  font-family: "Branding Thin";
  src: url(./fonts/Branding-Thin.woff) format("woff");
}

body {
  font-family: "Branding Bold";
}
