@import "../../theme";

.menu-section {
  & > * {
    background: rgba(253, 254, 255, 0.85);
    color: #6b6b6b;
  }

  .section-header {
    font-size: 2.5rem;
    margin-bottom: 0.2rem;
    padding: 0;
    // height: calc(25% - 0.2rem);
  }

  .section-items {
    padding: 0.25rem 0.75rem;
  }
}

.menu-section-yellow:hover {
  background: $yellow;
}

.menu-section-green:hover {
  background: $green;
}

.menu-section-red:hover {
  background: $red;
}

.menu-section-purple:hover {
  background: $purple;
}

@media only screen and (min-width: 768px) {
  .menu-section {
    width: 40%;
  }
}
