@import "../../../theme";

.whatsapp-contact-us-banner {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Branding Bold";

  max-width: 60%;
  margin: 0 auto;
  padding: 0.2rem;

  position: fixed;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  z-index: 999;

  background: $red;
  border: 3.5px solid white;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.8) 0px 1px 10px 0px;
}

.whatsapp-contact-us-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  color: white;

  .whatsapp-contact-logo {
    padding-right: 0.25rem;
    width: 2rem;
  }
}
