@import "../../../theme";
.lds-ripple {
  display: inline-block;
  position: relative;
  opacity: 1;
  animation-name: flash_border;
  animation-direction: alternate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
}

@keyframes flash_border {
  0% {
    border-color: $yellow;
    fill: $yellow;
  }
  25% {
    border-color: $green;
    fill: $green;
  }
  75% {
    border-color: $red;
    fill: $red;
  }
  100% {
    border-color: $purple;
    fill: $purple;
  }
}
