:root {
  --stripe-1: #fdc111; /* yellow */
  --stripe-2: #00ad5e; /* green */
  --stripe-3: #d33136; /* red */
  --stripe-4: #8f3192; /* purple */
}

.menu {
  z-index: 1;
  text-align: center;
  .menu-title {
    color: rgba(253, 254, 255, 0.9);
    font-size: 5.25rem;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 10px rgba(100, 100, 111, 0.8);
  }

  .menu-sections {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  .menu-legend > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .menu-legend-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .menu-legend-item-logo {
    width: 1.5rem;
    height: 1.5rem;
  }
  .menu-legend-item-text {
    padding-left: 0.25rem;
    color: #6b6b6b;
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 768px) {
  .menu {
    margin-bottom: 12rem;
  }

  .whatsapp-contact-us-btn,
  .sinpe-banner {
    max-width: 40%;
  }
}

@media only screen and (max-width: 320px) {
  .whatsapp-contact-us-btn {
    max-width: calc(90% - 1.5rem);
  }
  .sinpe-banner {
    max-width: 100%;
  }
}
