@import "../../theme";

.cx-wallet-component {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.cx-wallet-label {
  margin: 0 auto;
  width: 55%;
  margin-top: 0.25rem;
  margin-bottom: 0.2rem;
}
.cx-wallet-input {
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: inherit;
  padding: 0 0.5rem;
  background-color: white;
}
