.redirect-on-success-message {
  font-size: 0.8rem;
}

.redirect-on-success-btn {
  margin: 0 auto;
  margin-top: 0.25rem;
  width: 80%;
  a {
    text-decoration: none;
  }
}

.redirect-on-success-card-generic {
  .ll-card-title {
    background-color: none;
    color: #6b6b6b;
  }
}
