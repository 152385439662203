@import "../../../theme";
.sign-up-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
}
.sign-up-form-wrapper {
  box-shadow: rgba(100, 100, 111, 0.8) 0px 1px 10px 0px;
}

.sign-up-form {
  text-align: center;

  input,
  label {
    display: block;
    margin: 0 auto;
  }
  input {
    color: #6b6b6b;
  }
  button:first-child {
    margin-right: 0.25rem;
  }
}

.sign-up-form-header,
.sign-up-form {
  background: rgba(253, 254, 255, 0.85);
  color: #6b6b6b;
  padding: 0 2rem;
}

.sign-up-form-header {
  margin: 0 auto;
  margin-bottom: 0.2rem;
}
.sign-up-form {
  padding: 0.5rem;
}

.sign-up-form-group {
  min-height: 8vh;
}

.sign-up-invalid-feedback {
  color: $red;
  font-size: 0.7rem;
}
