@import "../../../theme";

.ll-card-container {
  width: 80%;
  margin: 0.8rem auto;
}
.ll-card-wrapper {
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.8) 0px 1px 10px 0px;
  text-align: center;
}

.ll-card-title,
.ll-card-content {
  background: rgba(253, 254, 255, 0.85);
  color: #6b6b6b;
}

.ll-card-title {
  margin: 0 auto;
  margin-bottom: 0.2rem;
}
.ll-card-content {
  padding: 0.5rem;
}

.ll-card-item-group {
  min-height: 8vh;
}
