.item-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-name {
  font-size: 1.2rem;
}

.item-description {
  font-size: 0.9rem;
  font-family: "Branding Medium";
}

.section-price {
  font-size: 1.8rem;
}

.item-badges > * {
  width: 10px;
  height: 14px !important;
}
