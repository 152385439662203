@import "./theme";

body {
  font-family: "Branding Bold";
  margin: 0;
  padding: 0;

  background: linear-gradient(
    to right,
    $yellow 0 25%,
    $green 25% 50%,
    $red 50% 75%,
    $purple 75%
  );
}

.App {
  width: 100%;
  padding: 0;
  margin: 0;
}

.web-component {
  display: inline-block;
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
  width: 100%;
  color: #6b6b6b;
  box-shadow: rgba(100, 100, 111, 0.8) 0px 1px 10px 0px;
}

.web-element {
  text-align: center;
  margin: 0;
  padding: 0.5rem;
  width: calc(100% - 1rem);
  background: rgba(253, 254, 255, 0.85);
}

//Buttons
.los-litros-generic-btn {
  margin-top: 0.25rem;
  border: 2px solid white;
  padding: 0.25rem 0.5rem;
  color: #6b6b6b;
  background: rgba(217, 218, 219, 0.85);
  font-family: "Branding Bold";
}
.los-litros-success-btn {
  background-color: $green;
  color: white;
}

.los-litros-warn-btn {
  background-color: $yellow;
  color: #6b6b6b;
}
