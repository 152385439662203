.wrapper {
  position: relative;
  text-align: center;
  width: calc(100% - 2rem);
  margin: 0;
  padding: 1rem;
  padding-bottom: 5rem;
  z-index: 0;
}
.wrapper {
  .los-litros-brand-min {
    position: absolute;
    width: 50%;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    margin-bottom: -0.25rem;
    z-index: 0;
  }
}
