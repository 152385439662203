.sign-in-container {
  margin: 1rem auto;
}

.sign-in-form,
.signing-in-content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sign-in-btn:disabled {
  background-color: lightgray;
}
